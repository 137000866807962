import { render } from 'preact-render-to-string';
import {
  Line,
  BasicEmailWrapper,
  MarketingFooter,
  PrimaryAction,
  Heading,
  SubHeading,
} from './shared';
import { Tenant } from 'server/types';
import { tenantIntl } from 'shared/intl/use-intl.server';
import { OTP_EXPIRATION_MINUTES } from 'shared/consts';

export function ForgotPassword({
  tenant,
  resetCode,
  returnURL,
}: {
  tenant: Tenant;
  resetCode: string;
  returnURL?: string;
}) {
  const signinUrl = `https://${
    tenant.domain
  }/one-click-login?code=${resetCode}&redirect=${encodeURIComponent(returnURL || '/')}`;
  const intl = tenantIntl(tenant);

  return render(
    <BasicEmailWrapper footer={<MarketingFooter />}>
      <Heading>
        {intl('Sign in to {tenantName:string}', {
          tenantName: tenant.name,
        })}
      </Heading>
      <Line>
        {intl(
          `Looks like you've requested a link to sign in to {tenantName:string}. Just click the link below and you'll be securely signed in.`,
          {
            tenantName: tenant.name,
          },
        )}
      </Line>
      <PrimaryAction href={signinUrl}>{intl('One-click sign in')}</PrimaryAction>
      <Line>
        {intl(`If that's not working, copy and paste this link into your browser's address bar:`)}
      </Line>
      <Line>
        <a
          href={signinUrl}
          target="_blank"
          style="word-wrap:break-word;word-break:break-all;"
          rel="noreferrer"
        >
          {signinUrl}
        </a>
      </Line>
      <Line>{intl(`If you didn't request this link, you can ignore this email.`)}</Line>
    </BasicEmailWrapper>,
  );
}

export function OtpEmailTemplate({ otp }: { otp: string }) {
  return render(
    <BasicEmailWrapper footer={<MarketingFooter />}>
      <SubHeading>Your login code</SubHeading>
      <Line>
        Please return to the login page and enter the code below to sign in. This code will expire
        in {OTP_EXPIRATION_MINUTES} minutes and can only be used once.
      </Line>
      <Heading>{otp}</Heading>
      <Line>If you didn't request this code, you can ignore this email.</Line>
    </BasicEmailWrapper>,
  );
}
