import { useDocumentTitle } from 'client/utils/use-document-title';
import ICAL from 'ical.js';
import { ruzcal, toQueryString } from 'shared/urls';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { IcoArrowLeft, IcoArrowRight } from '@components/icons';
import { MeetingSummary } from './meeting-summary';
import { AsyncForm, FormGroup } from '@components/async-form';
import { Field, InputField, eventToState } from './form-helpers';
import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary } from '@components/buttons';
import { ScheduleBooking } from './schedule-picker';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useAuth } from 'client/lib/auth';
import { randomString } from 'shared/auth';
import { showError } from '@components/app-error';
import { useRouteParams, LoadedProps, RouteLoadProps, useRouter } from '@components/router';
import { UserProfileIcon } from '@components/avatars';
import { useAsyncData, useLocalStorageState } from 'client/lib/hooks';
import { getICalEventDates } from 'shared/scheduling/ical';
import { useState } from 'preact/hooks';
import { defCalRoute } from './common';
import { ERROR_CODES } from 'shared/consts';
import { OtpInput } from '@components/otp-input';
import { SessionUser } from 'server/types';

type Pane = 'date' | 'confirm' | 'otp';

export const route = defCalRoute({ isPublic: true, load, Page });

function parseRouteSchedule(params: Record<string, string>) {
  const { start, end } = params;
  if (!start || !end) {
    return;
  }
  try {
    return {
      start: new Date(start),
      end: new Date(end),
    };
  } catch (err) {
    console.error(err);
  }
}

type LoadedState = RpxResponse<typeof rpx.ruzcal.getInitialBookingState> & {
  error: undefined;
  schedule?: { start: Date; end: Date };
  baseUrl: string;
  attendeeTimeZone: string;
  name: string;
  email: string;
  notes: string;
  attendeePassword: string;
  hour12: boolean;
  pane: Pane;
};

type ErrorState = { type: 'error'; error: Error };

type State = LoadedState | ErrorState;

async function load(route: RouteLoadProps): Promise<State> {
  const { urlPrefix, urlSuffix } = route.params;
  try {
    const state = await rpx.ruzcal.getInitialBookingState({
      urlPrefix,
      urlSuffix,
    });
    const schedule = parseRouteSchedule(route.params);

    const hour12 = localStorage.ruzcalHour12
      ? localStorage.ruzcalHour12 === 'true'
      : route.params.hour12 !== 'false';

    const attendeeTimeZone =
      route.auth.user?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      state.availability.timezone;

    return {
      ...state,
      error: undefined,
      schedule,
      baseUrl: ruzcal.newBookingUrl({ urlPrefix, urlSuffix }),
      attendeeTimeZone,
      name: '',
      email: '',
      notes: '',
      attendeePassword: '',
      hour12,
      pane: route.params.pane as Pane,
    };
  } catch (err) {
    console.error(err);
    return {
      type: 'error',
      error: err,
    };
  }
}

type Props = LoadedProps<typeof load>;

function paneHref(opts: { pane: Pane; schedule: LoadedState['schedule']; hour12: boolean }) {
  const { pane, schedule, hour12 } = opts;
  return toQueryString(
    {
      start: schedule?.start.toISOString(),
      end: schedule?.end.toISOString(),
      hour12: String(hour12),
      pane: schedule ? pane : 'date',
    },
    '?',
  );
}

function OtpForm({ email, onLogin }: { email: string; onLogin: (user: SessionUser) => void }) {
  const [otp, setOtp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <AsyncForm
      class="flex grow flex-col gap-4"
      onSubmit={async () => {
        setIsSubmitting(true);
        try {
          const user = await rpx.auth.loginWithOtp({ email, otp });
          onLogin(user);
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      <h3 class="font-bold text-lg">Welcome back!</h3>
      <p class="">To continue, please enter the code sent {email}</p>
      <FormGroup class="grow" prop="otp">
        <OtpInput length={6} onChange={setOtp} />
      </FormGroup>
      <BtnPrimary
        class="inline-flex text-left gap-2 items-center p-3 px-4 rounded-full text-base min-w-40"
        disabled={otp.length < 6}
        isLoading={isSubmitting}
      >
        Continue
        <IcoArrowRight class="size-4 shrink-0" />
      </BtnPrimary>
    </AsyncForm>
  );
}

const BOOKING_FORM_STATE_KEY = 'ruzcal/booking-form';

function BookingForm(props: {
  ics: undefined | string;
  pane: Pane;
  state: LoadedState;
  setState: Props['setState'];
}) {
  const { state } = props;
  const { schedule, eventType } = state;
  const auth = useAuth();
  const router = useRouter();

  const [formState, setFormState] = useLocalStorageState(BOOKING_FORM_STATE_KEY, {
    name: state.name,
    email: state.email,
    notes: state.notes,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function completeBooking() {
    const schedule = state.schedule!;

    if (props.ics) {
      const cal = new ICAL.Component(ICAL.parse(props.ics));
      const isBooked = getICalEventDates(cal, schedule.start, schedule.end).next().value;
      if (isBooked) {
        setIsSubmitting(false);
        throw new Error(`This time is no longer available.`);
      }
    }

    try {
      const result = await rpx.ruzcalEvents.createEvent({
        end: schedule.end,
        start: schedule.start,
        eventTypeId: eventType.id,
        notes: formState.notes,
      });
      // Clear the local storage form state
      setFormState({ name: '', email: '', notes: '' });
      location.assign(ruzcal.existingBookingUrl({ id: result.id, success: true }));
      // Saving this until after we do the redirect, as it causes an unpleasant flicker...
      auth.setUser(auth.user);
    } catch (err) {
      showError(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  if (!schedule) {
    return null;
  }

  if (props.pane === 'otp' && formState.email) {
    return (
      <OtpForm
        email={formState.email}
        onLogin={async (user) => {
          auth.setUser(user);
          await completeBooking();
        }}
      />
    );
  }

  return (
    <AsyncForm
      class="flex flex-col gap-4 border-t pt-8 sm:pt-0 sm:border-0 sm:border-l sm:pl-8"
      onSubmit={async () => {
        setIsSubmitting(true);
        if (!auth.user) {
          try {
            auth.user = await rpx.auth.registerNewUser({
              timezone: state.attendeeTimeZone,
              name: formState.name,
              email: formState.email,
              password: randomString(24, 32),
            });
          } catch (err) {
            setIsSubmitting(false);
            if (err.data?.code === ERROR_CODES.userExists) {
              await rpx.auth.sendOtpCodeEmail({ email: formState.email });
              router.goto(paneHref({ pane: 'otp', schedule, hour12: state.hour12 }));
              return;
            }
            throw err;
          }
        }

        await completeBooking();
      }}
    >
      {auth.user && (
        <>
          <span class="flex items-center gap-3">
            <UserProfileIcon user={auth.user} size="size-9" />
            <span class="flex flex-col leading-5">
              <strong>{auth.user.name}</strong>
              <span class="opacity-75">{auth.user.email}</span>
            </span>
          </span>

          <hr />
        </>
      )}
      {!auth.user && (
        <InputField
          name="name"
          title="Your name"
          fullWidth
          autoFocus
          value={formState.name}
          onInput={eventToState(setFormState)}
        />
      )}
      {!auth.user && (
        <InputField
          name="email"
          fullWidth
          autoFocus={!!auth.user}
          title="Email address"
          value={formState.email}
          onInput={eventToState(setFormState)}
        />
      )}
      <Field name="notes" title="Additional notes">
        <AutosizeText
          name="notes"
          class="ruz-input min-h-20 p-2 px-3"
          autoFocus={!!auth.user}
          value={formState.notes}
          onInput={eventToState(setFormState)}
        />
      </Field>
      <footer class="mt-4">
        <BtnPrimary
          class="inline-flex text-left gap-2 items-center p-3 px-4 rounded-full text-base"
          isLoading={isSubmitting}
        >
          Schedule meeting
          <IcoArrowRight class="size-4 shrink-0" />
        </BtnPrimary>
      </footer>
    </AsyncForm>
  );
}

function ConfirmBooking(props: {
  ics: undefined | string;
  pane: Pane;
  state: LoadedState;
  setState: Props['setState'];
}) {
  const { state } = props;
  const { schedule, eventType, host } = state;

  if (!schedule) {
    return null;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen">
      <section class="p-8 bg-white rounded-2xl max-w-full w-3xl an-scale-in flex-col gap-8 grid sm:grid-cols-2">
        <div>
          <a
            class="inline-flex gap-2 items-center mb-4 font-medium"
            href={paneHref({
              pane: props.pane === 'otp' ? 'confirm' : 'date',
              schedule,
              hour12: state.hour12,
            })}
          >
            <IcoArrowLeft />
            Back
          </a>
          <MeetingSummary
            host={host}
            name={eventType.name}
            description={eventType.description}
            duration={eventType.duration}
            location={eventType.location}
            locationDetail={eventType.locationDetail}
            timeZone={state.attendeeTimeZone}
            hour12={state.hour12}
            schedule={schedule}
          />
        </div>
        <BookingForm {...props} />
      </section>
    </div>
  );
}

function LoadedPage(props: { state: LoadedState; setState: Props['setState'] }) {
  const params = useRouteParams();
  const { state, setState } = props;
  const { eventType } = props.state;
  const pane = params.pane as Pane;
  const ics = useAsyncData(async () => {
    if (state.host.hasICal) {
      return rpx.ruzcal.getHostIcal({ hostId: state.host.userId });
    }
  }, []);

  useDocumentTitle([eventType.name]);

  useDidUpdateEffect(() => {
    localStorage.ruzcalHour12 = state.hour12;
  }, [state.hour12]);

  if ((pane === 'confirm' || pane === 'otp') && state.schedule && params.start) {
    // This is a hack to make sure the schedule is set correctly.
    const schedule = parseRouteSchedule(params);
    state.schedule = schedule;
    return <ConfirmBooking ics={ics.data} state={state} setState={setState} pane={pane} />;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-5xl">
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 gap-10">
          <section>
            <MeetingSummary
              host={state.host}
              name={eventType.name}
              description={eventType.description}
              duration={eventType.duration}
              location={eventType.location}
              locationDetail={eventType.locationDetail}
              timeZone={state.attendeeTimeZone}
              hour12={state.hour12}
            />
          </section>
          {!ics.isLoading && (
            <ScheduleBooking
              eventType={eventType}
              hasGoogle={state.host.hasGoogle}
              ics={ics.data}
              availability={state.availability}
              attendeeTimeZone={state.attendeeTimeZone}
              schedule={state.schedule}
              hour12={state.hour12}
              onScheduleChange={(schedule) => setState((s) => ({ ...s, schedule }))}
              onHour12Change={(hour12) => setState((s) => ({ ...s, hour12 }))}
              makeHref={(opts) =>
                paneHref({ pane: 'confirm', schedule: opts.schedule, hour12: state.hour12 })
              }
            />
          )}
          {ics.isLoading && (
            <>
              <section class="lg:col-span-2 sm:border-t-0 py-10 sm:pt-0 lg:p-0 lg:border-0 lg:px-4 animate-pulse rounded-2xl aspect-square grid grid-cols-1 gap-4 relative">
                <div class="bg-gray-100 rounded-2xl"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gray-200 rounded-full"></div>
                <div class="bg-gradient-to-b from-transparent to-white absolute inset-0"></div>
              </section>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

function Page(props: LoadedProps<typeof load>) {
  if (props.state.error) {
    return (
      <div class="flex flex-col gap-8 items-center justify-center bg-gray-100 min-h-screen text-center">
        <div class="text-lg">
          <h1 class="text-2xl font-semibold">Whoops!</h1>
          {(props.state.error as Error & { statusCode?: number }).statusCode === 404 ? (
            <p>We couldn't find the booking page you were looking for.</p>
          ) : (
            <p>Something went wrong.</p>
          )}
        </div>
        <footer>
          <BtnPrimary href="/" class="inline-flex gap-2 items-center rounded-full">
            <IcoArrowLeft />
            Go to home page
          </BtnPrimary>
        </footer>
      </div>
    );
  }
  return <LoadedPage state={props.state} setState={props.setState} />;
}
