import { useState, useRef } from 'preact/hooks';

interface Props {
  length: number;
  class?: string;
  onChange: (otp: string) => void;
}

export function OtpInput({ length, class: className = '', onChange }: Props) {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
  const inputsRef = useRef<HTMLInputElement[]>([]);

  return (
    <div class={`flex gap-1 ${className}`}>
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          name="otp"
          class="w-12 h-12 text-center text-2xl border border-gray-300 rounded-lg"
          type="text"
          maxLength={1}
          value={otp[index]}
          onChange={(e: any) => {
            const value = e.target.value;
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            onChange(newOtp.join(''));

            // Focus to the next input if the current one is filled
            if (value && index < length - 1) {
              inputsRef.current[index + 1].focus();
            }
          }}
          onKeyDown={(e: any) => {
            // Move to the previous input if the current one is empty
            if (e.key === 'Backspace' && !otp[index] && index > 0) {
              inputsRef.current[index - 1].focus();
            }
          }}
          // Paste the OTP if it's the correct length and all numbers
          onPaste={(e: any) => {
            const pasteData = e.clipboardData.getData('text');
            if (pasteData.length === length && /^\d+$/.test(pasteData)) {
              const newOtp = pasteData.split('');
              setOtp(newOtp);
              onChange(newOtp.join(''));
              inputsRef.current[length - 1].focus();
            }
          }}
          ref={(el) => (inputsRef.current[index] = el!)}
        />
      ))}
    </div>
  );
}
